var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        fullscreen: true,
        "destroy-on-close": true,
        "lock-scroll": true,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        open: _vm.initSize,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "titleContainer flex-row",
          attrs: { slot: "title" },
          slot: "title",
        },
        [_c("span", { staticClass: "text-18-black33-b" }, [_vm._v("签名定位")])]
      ),
      _vm._v(" "),
      _c("div", { ref: "outContainer", staticClass: "flex-row outContainer" }, [
        _c(
          "div",
          {
            staticClass: "flex1 flex-col-center-jx back-color-grayE5",
            staticStyle: { border: "1px solid #666666" },
          },
          [
            _c("div", { staticClass: "pdfPageHandler flex-row-center-jx" }, [
              _c("i", {
                staticClass: "el-icon-d-arrow-left icon-30 btn-hover-el",
                on: {
                  click: function ($event) {
                    return _vm.switchPage(false)
                  },
                },
              }),
              _vm._v(" "),
              _c("input", {
                staticClass: "indexInput",
                attrs: { type: "number" },
                domProps: { value: _vm.curPdfIndex },
                on: { change: _vm.limitPage },
              }),
              _vm._v(" "),
              _vm.pdfPages.length !== 1
                ? _c("span", { staticClass: "pageFontSize" }, [
                    _vm._v("/" + _vm._s(_vm.pdfPages.length) + " "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("i", {
                staticClass: "el-icon-d-arrow-right icon-30 btn-hover-el",
                on: { click: _vm.switchPage },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                ref: "pdfBox",
                staticClass:
                  "pdfBox match-parent flex-col-center-jx position-r",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "pdfPicContainer",
                    style: _vm.picContainerSize,
                    on: {
                      dragover: function ($event) {
                        return $event.preventDefault()
                      },
                      drop: function ($event) {
                        return _vm.onDragStop($event)
                      },
                    },
                  },
                  [
                    _vm.curPdfPage
                      ? _c("img", {
                          ref: "bg-image",
                          staticClass: "pdfPic match-parent",
                          attrs: { src: _vm.curPdfPage, alt: "" },
                        })
                      : _c("span", { staticClass: "noPdfHint" }, [
                          _vm._v("暂无数据哦"),
                        ]),
                    _vm._v(" "),
                    _vm._l(_vm.locate, function (rect, index) {
                      return rect.pageCode === _vm.curPdfIndex
                        ? _c(
                            "div",
                            {
                              key: index,
                              staticClass:
                                "dragRect border-box little-hand-move",
                              style: _vm.styleRect(rect),
                              attrs: { draggable: "true" },
                              on: {
                                dragstart: function ($event) {
                                  return _vm.onDragStart(index, $event)
                                },
                              },
                            },
                            [
                              rect.pageCode === _vm.curPdfIndex
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "position-r match-parent flex-row-center hide-text",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "deleteIcon flex-row-center position-a little-hand",
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteLocate(index)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "x\n                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      rect.type === 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "position-r flex-row-center match-parent",
                                            },
                                            [
                                              rect.orgType === "notaryAgency"
                                                ? _c("img", {
                                                    staticClass: "match-parent",
                                                    attrs: {
                                                      src: require("../image/seal.png"),
                                                      alt: "",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              rect.orgType === "financial"
                                                ? _c("img", {
                                                    staticClass: "match-parent",
                                                    attrs: {
                                                      src: require("../image/Hseal.png"),
                                                      alt: "",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "position-a signatureIndex",
                                                },
                                                [_vm._v(_vm._s(rect.index))]
                                              ),
                                            ]
                                          )
                                        : rect.type === 1
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "fontSize10 color-redFF",
                                            },
                                            [_vm._v(_vm._s(rect.name))]
                                          )
                                        : rect.type === 2
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "fontSize10 color-redFF",
                                            },
                                            [_vm._v(_vm._s(rect.name))]
                                          )
                                        : rect.type === 3
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex-col-center match-parent",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "fontSize10 color-redFF flex-row-center flex1",
                                                },
                                                [_vm._v(_vm._s(rect.name))]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "fontSize10 color-redFF flex-row-center flex1",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(rect.timeData.name)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : rect.type === 4
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex-col-center match-parent",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "flex-row-center flex1",
                                                },
                                                [
                                                  rect.orgType ===
                                                  "notaryAgency"
                                                    ? _c("img", {
                                                        staticClass:
                                                          "match-parent",
                                                        attrs: {
                                                          src: require("../image/seal.png"),
                                                          alt: "",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  rect.orgType === "financial"
                                                    ? _c("img", {
                                                        staticClass:
                                                          "match-parent",
                                                        attrs: {
                                                          src: require("../image/Hseal.png"),
                                                          alt: "",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "position-a signatureIndex",
                                                    },
                                                    [_vm._v(_vm._s(rect.index))]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "fontSize10 color-redFF flex-row-center",
                                                  style: {
                                                    height:
                                                      rect.timeData.height +
                                                      "px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(rect.timeData.name)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e()
                    }),
                  ],
                  2
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "flex-col signatureContainer",
            staticStyle: { padding: "0 30px 0 10px" },
          },
          [
            _c(
              "span",
              { staticClass: "text-16-black33 font-bold text-start barStyle" },
              [_vm._v("签名/签章")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "barStyle borderTop flex-row-center-jx flex-between color-orangeFB fontSize14",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex-row-center little-hand width100px",
                    on: { click: _vm.addTimeCurPage },
                  },
                  [
                    _c("img", {
                      staticClass: "icon-14",
                      attrs: {
                        src: require("../image/icon_clock.svg"),
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("添加签字时间")]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "bottom-pop-view",
                  { staticClass: "addBtn", attrs: { "pop-width": "208px" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex-row-center",
                        attrs: { slot: "triggerContent" },
                        slot: "triggerContent",
                      },
                      [
                        _c("img", {
                          staticClass: "icon-14",
                          attrs: {
                            src: require("../image/icon_copy.svg"),
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("批量设置")]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "bottomPopContainer flex-col",
                        attrs: { slot: "popContent" },
                        slot: "popContent",
                      },
                      _vm._l(_vm.batchAddOption, function (option, count) {
                        return _c(
                          "span",
                          {
                            key: count,
                            staticClass: "popItem border-box little-hand",
                            on: {
                              click: function ($event) {
                                return _vm.addSignByOption(option)
                              },
                            },
                          },
                          [_vm._v(_vm._s(option.label))]
                        )
                      }),
                      0
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "signatureItem" }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "signatureList flex-col" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex-row flex-between margin-top-8 signatureLine font-weight-500",
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "text-14-black33 signatureItemMini flex-row-center",
                      },
                      [_vm._v("序号")]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "text-14-black33 signatureItem width100px flex-row-center-jx",
                      },
                      [_vm._v("人员/机构")]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "text-14-black33 signatureItemMini flex-row-center",
                      },
                      [_vm._v("状态")]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "text-14-black33 signatureItemMini flex-row-center",
                      },
                      [_vm._v("操作")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm._l(_vm.signatureList, function (sign, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass:
                        "flex-row-center-jx flex-between margin-top-8 signatureLine",
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-14-gray66 signatureItemMini flex-row-center",
                        },
                        [_vm._v(_vm._s(index + 1))]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "color-gray66 signatureItem flex-row-center-jx width100px",
                        },
                        [_vm._v(_vm._s(sign.name))]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "signatureStatus signatureItemMini",
                          class: _vm.getSignatureClass(sign),
                        },
                        [_vm._v(_vm._s(_vm.getSignStatus(sign)))]
                      ),
                      _vm._v(" "),
                      _c(
                        "bottom-pop-view",
                        {
                          staticClass: "signatureItemMini",
                          attrs: { "pop-width": "200px" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "flex-row-center match-parent",
                              attrs: { slot: "triggerContent" },
                              slot: "triggerContent",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex-row-center text-14-orangeFF little-hand",
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-plus color-orangeFF icon-14",
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-left": "2px" } },
                                    [_vm._v("添加")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "bottomPopContainer flex-col",
                              attrs: { slot: "popContent" },
                              slot: "popContent",
                            },
                            _vm._l(_vm.addOption, function (option, count) {
                              return _c(
                                "span",
                                {
                                  key: count,
                                  staticClass: "popItem border-box little-hand",
                                  on: {
                                    click: function ($event) {
                                      return _vm.addSignByOption(
                                        option,
                                        sign,
                                        index
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(option.label))]
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.clearAllPage } }, [
            _vm._v("清除全部设置"),
          ]),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.clearCurPage } }, [
            _vm._v("清除本页设置"),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { "background-color": "#F2931D" },
              attrs: { type: "warning" },
              on: { click: _vm.copyCurDataToAllPage },
            },
            [_vm._v("应用到所有页")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { "background-color": "#F2931D" },
              attrs: { type: "warning" },
              on: { click: _vm.saveLocation },
            },
            [_vm._v("保存设置")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }