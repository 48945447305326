import pdfEditor from './components/pdfEditor'
import './css/form-style.scss'
import ElementUI from 'element-ui';

const vueComponent = {
    install: (app) => {
        app.use(ElementUI);
        app.config.productionTip = false;

        app.component('pdfEditor', pdfEditor)
    }
}
export default vueComponent;
