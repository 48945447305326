<template>
    <el-dialog :visible.sync="dialogVisible" :fullscreen="true" :destroy-on-close="true" :lock-scroll="true"
               :before-close="cancel" @open="initSize">
        <div slot="title" class="titleContainer flex-row">
            <span class="text-18-black33-b">签名定位</span>
        </div>

        <div class="flex-row outContainer" ref="outContainer">
            <div class="flex1 flex-col-center-jx back-color-grayE5" style="border:1px solid #666666;">
                <div class="pdfPageHandler flex-row-center-jx">
                    <i class="el-icon-d-arrow-left icon-30 btn-hover-el" @click="switchPage(false)"/> <input
                    :value="curPdfIndex" class="indexInput" type="number" @change="limitPage"/> <span
                    v-if="pdfPages.length!==1" class="pageFontSize">/{{pdfPages.length}} </span> <i
                    class="el-icon-d-arrow-right icon-30 btn-hover-el" @click="switchPage"></i>
                </div>
                <div class="pdfBox match-parent flex-col-center-jx position-r" ref="pdfBox">
                    <div :style="picContainerSize" class="pdfPicContainer" @dragover="$event.preventDefault()"
                         @drop="onDragStop($event)">
                        <img v-if="curPdfPage" class="pdfPic match-parent" :src="curPdfPage" alt="" ref="bg-image"/>
                        <span v-else class="noPdfHint">暂无数据哦</span>
                        <div v-for="(rect, index) in locate" :key="index" :style="styleRect(rect)"
                             class="dragRect border-box little-hand-move" draggable="true"
                             @dragstart="onDragStart(index,$event)" v-if="rect.pageCode === curPdfIndex">
                            <div v-if="rect.pageCode === curPdfIndex"
                                 class="position-r match-parent flex-row-center hide-text" @click.stop.prevent>
                                <div class="deleteIcon flex-row-center position-a little-hand"
                                     @click="deleteLocate(index)">x
                                </div>
                                <div v-if="rect.type === 0" class="position-r flex-row-center match-parent">
                                    <img v-if="rect.orgType==='notaryAgency'" class="match-parent"
                                         src="../image/seal.png" alt=""/> <img v-if="rect.orgType==='financial'"
                                                                               class="match-parent"
                                                                               src="../image/Hseal.png" alt=""/> <span
                                    class="position-a signatureIndex">{{ rect.index }}</span>
                                </div>
                                <span v-else-if="rect.type === 1" class="fontSize10 color-redFF">{{rect.name}}</span>
                                <span v-else-if="rect.type === 2" class="fontSize10 color-redFF">{{rect.name}}</span>
                                <div v-else-if="rect.type === 3" class="flex-col-center match-parent">
                                    <span class="fontSize10 color-redFF flex-row-center flex1">{{rect.name}}</span>
                                    <span
                                        class="fontSize10 color-redFF flex-row-center flex1">{{rect.timeData.name}}</span>
                                </div>
                                <div v-else-if="rect.type === 4" class="flex-col-center match-parent">
                                    <div class="flex-row-center flex1">
                                        <img v-if="rect.orgType==='notaryAgency'" class="match-parent"
                                             src="../image/seal.png" alt=""/> <img v-if="rect.orgType==='financial'"
                                                                                   class="match-parent"
                                                                                   src="../image/Hseal.png" alt=""/>
                                        <span class="position-a signatureIndex">{{ rect.index }}</span>
                                    </div>
                                    <span class="fontSize10 color-redFF flex-row-center"
                                          :style="{height: `${rect.timeData.height}px`}">{{rect.timeData.name}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex-col signatureContainer" style="padding: 0 30px 0 10px">
                <span class="text-16-black33 font-bold text-start barStyle">签名/签章</span>
                <div class="barStyle borderTop flex-row-center-jx flex-between color-orangeFB fontSize14">
                    <div class="flex-row-center little-hand width100px" @click="addTimeCurPage">
                        <img src="../image/icon_clock.svg" alt="" class="icon-14"/> <span>添加签字时间</span>
                    </div>
                    <bottom-pop-view class="addBtn" pop-width="208px">
                        <div slot="triggerContent" class="flex-row-center">
                            <img src="../image/icon_copy.svg" alt="" class="icon-14"/> <span>批量设置</span>
                        </div>
                        <div slot="popContent" class="bottomPopContainer flex-col">
                            <span v-for="(option,count) in batchAddOption" :key="count"
                                  class="popItem border-box little-hand"
                                  @click="addSignByOption(option)">{{option.label}}</span>
                        </div>
                    </bottom-pop-view>
                    <div class="signatureItem"/>
                </div>
                <div class="signatureList flex-col">
                    <div class="flex-row flex-between margin-top-8 signatureLine font-weight-500">
                        <span class="text-14-black33 signatureItemMini flex-row-center">序号</span> <span
                        class="text-14-black33 signatureItem width100px flex-row-center-jx">人员/机构</span> <span
                        class="text-14-black33 signatureItemMini flex-row-center">状态</span> <span
                        class="text-14-black33 signatureItemMini flex-row-center">操作</span>
                    </div>

                    <div v-for="(sign,index) in signatureList" :key="index"
                         class="flex-row-center-jx flex-between margin-top-8 signatureLine">
                        <span class="text-14-gray66 signatureItemMini flex-row-center">{{ index + 1}}</span> <span
                        class="color-gray66 signatureItem flex-row-center-jx width100px">{{ sign.name }}</span> <span
                        class="signatureStatus signatureItemMini"
                        :class="getSignatureClass(sign)">{{ getSignStatus(sign) }}</span>
                        <bottom-pop-view class="signatureItemMini" pop-width="200px">
                            <div slot="triggerContent" class="flex-row-center match-parent">
                                <div class="flex-row-center text-14-orangeFF little-hand">
                                    <i class="el-icon-plus color-orangeFF icon-14"/> <span
                                    style="margin-left: 2px;">添加</span>
                                </div>
                            </div>
                            <div slot="popContent" class="bottomPopContainer flex-col">
                                <span v-for="(option,count) in addOption" :key="count"
                                      class="popItem border-box little-hand"
                                      @click="addSignByOption(option,sign,index)">{{option.label}}</span>
                            </div>
                        </bottom-pop-view>
                    </div>
                </div>
            </div>
        </div>

        <div slot="footer" class="dialog-footer">
            <el-button @click="clearAllPage">清除全部设置</el-button>
            <el-button @click="clearCurPage">清除本页设置</el-button>
            <el-button type="warning" style="background-color: #F2931D" @click="copyCurDataToAllPage">应用到所有页</el-button>
            <el-button type="warning" style="background-color: #F2931D" @click="saveLocation">保存设置</el-button>
        </div>
    </el-dialog>

</template>

<script>
import verify from "../tool/verify";
import BottomPopView from "./bottomPopView";

const PageConfig = {
    maxLengthPadding: 16,// 长边间距-单位:px
    timeWidth: 140,
    timeHeight: 50,
};

export default {
    name: 'pdfEditor',
    components: {BottomPopView},
    data() {
        return {
            eventMaterialId: '',
            dialogVisible: false,
            couldDrag: true,
            boxWidth: -1,
            boxHeight: -1,
            locate: [],// 所有签名定位信息
            locateCopy: [],
            signatureList: [], //右边列表数据
            pdfPages: [],
            curPdfIndex: 1,

            paperSize: null,
            scaleWidth: -1,
            scaleHeight: -1,
            scale: 1,
            factor: 0,//移动系数

            // 批量添加的选项
            batchAddOption: [
                {type: 'all', order: 'addCurPage', label: '添加到当前页'},
                {type: 'all', order: 'addAllPage', label: '添加到所有页'},
                {type: 'allWithTime', order: 'addCurPage', label: '添加到当前页（含时间）'},
                {type: 'allWithTime', order: 'addAllPage', label: '添加到所有页（含时间）'},
                {type: 'time', order: 'addAllPage', label: '添加到所有页（签字时间）'},
                // {type: 'copyToAll', order: 'addAllPage', label: '应用到所有页'},
            ],
            // 单独添加选项
            addOption: [
                {type: 'single', order: 'addCurPage', label: '添加签名签章'},
                {type: 'single', order: 'addAllPage', label: '添加到所有页'},
                {type: 'singleWithTime', order: 'addCurPage', label: '添加签名签章（含时间)'},
                {type: 'singleWithTime', order: 'addAllPage', label: '添加到所有页（含时间）'},
            ],

            copyAndClearOther: true,// 在复刻的同时是否清除掉之前的签字数据
            // copyAndClearOther: false,
            // 批量排序添加相关
            curArrangeLineSignMaxHeight: 0,
            arrangeDivideX: 10,// 排序x方向间距
            arrangeDivideY: 5,// 排序Y方向间距
        }
    },
    props: {
        isVertical: {
            type: Boolean,
            default: () => {
                return true;
            },
        },
    },
    computed: {
        curPdfPage() {
            if (verify.isEmptyArray(this.pdfPages)) {
                // 如果没得pdf设置进来
                return null
            }
            return this.pdfPages[this.curPdfIndex - 1];
        },
        picContainerSize() {
            let size = `height:${this.scaleHeight}px;width:${this.scaleWidth}px;`;
            if (this.scaleHeight <= this.boxHeight) {
                size += `top:${(this.boxHeight - this.scaleHeight) * 0.5}px;`;
            }
            if (this.scaleWidth <= this.boxWidth) {
                size += `left:${(this.boxWidth - this.scaledWhiteness) * 0.5}px;`;
            }
            return size;
        }
    },
    watch: {
        scale: {
            handler(newScale, oldScale) {
                console.log("handler-scale发生了变化=", newScale);
                console.log("handler-oldScale=", oldScale);
                let scaleStep = newScale / oldScale;// 变化的scale差值
                if (this.locate && this.locate.length > 0) {
                    this.locate = this.locate.map(locate => {
                        locate.width *= scaleStep;
                        locate.height *= scaleStep;
                        locate.lx = locate.lx * scaleStep;
                        locate.ly = locate.ly * scaleStep;
                        return locate;
                    });
                }
            }, immediate: false
        },
        locate: {
            handler(newValue) {
                console.log(`定位数据发生变化，新值=`, newValue);
                if (!this.signatureList || this.signatureList.length < 1) return;
                let signatureCopy = JSON.parse(JSON.stringify(this.signatureList));
                // 定位数据发生变化，去判定右侧有无指定
                for (let i = 0; i < signatureCopy.length; i++) {
                    let curSignature = signatureCopy[i];
                    this.signatureList[i].haveSet = newValue.some(item => {
                        switch (curSignature.type) {
                            case 0:
                                return item.sealCode === curSignature.sealCode;
                            case 1:
                                return item.litigantId === curSignature.litigantId;
                            case 3:
                                // 签名+时间
                                let signData1 = item.signData;
                                return signData.litigantId === curSignature.litigantId;
                            case 4:
                                // 签章+时间
                                let signData2 = item.signData;
                                return signData.sealCode === curSignature.sealCode;
                            default:
                                return false;
                        }
                    });
                }
            },
        },
    },
    mounted() {
        window.addEventListener('resize', this.initSize);
        // window.addEventListener('DOMMouseScroll', this.eventPrevent, {capture: false, passive: false});
        // window.addEventListener('mousewheel', this.eventPrevent, {capture: false, passive: false});
    },
    methods: {
        eventPrevent(domEvent) {
            console.log("event", domEvent);
            let targetEvent = domEvent || window.event;
            if ((targetEvent.wheelDelta && targetEvent.ctrlKey) || (targetEvent.detail && targetEvent.ctrlKey)) {
                targetEvent.preventDefault();
            }
        },
        clearAllPage() {
            this.locate = [];
        },
        clearCurPage() {
            let filter = this.locate.filter(pos => pos.pageCode !== this.curPdfIndex);
            console.log("clearCurPage-filter=", filter);
            this.locate = filter;
        },
        addSignByOption(option, signInfo, index) {
            console.log("addSignByOption-option=", option);
            console.log("addSignByOption-signInfo=", signInfo);
            let {type, order} = option;
            if (order === 'addCurPage') {
                // 在当前页面签
                switch (type) {
                    case 'single':
                        this.addNewSign(signInfo, index);
                        break;
                    case 'singleWithTime':
                        this.addNewSignWithTime(signInfo, index);
                        break;
                    case 'all':
                        this.addAllSignThisPageArrange();
                        // this.addAllSignThisPage(signInfo, index);
                        break;
                    case 'allWithTime':
                        this.addAllSignWithTimeThisPageArrange();
                        // this.addAllSignWithTimeThisPage(signInfo, index);
                        break;
                }
            } else {
                switch (type) {
                    case 'single':
                        this.addNewSignAllPage(signInfo, index);
                        break;
                    case 'singleWithTime':
                        this.addNewSignWithTimeAllPage(signInfo, index);
                        break;
                    case 'all':
                        this.addAllSignAllPage();
                        break;
                    case 'allWithTime':
                        this.addAllSignWithTimeAllPage();
                        break;
                    case 'time':
                        this.addTimeAllPage();
                        break;
                    // case 'copyToAll':
                    //     this.copyCurDataToAllPage();
                    //     break;
                }
            }

        },
        initSize() {
            return new Promise(resolve => {
                this.$nextTick(() => {
                    // 获得了容器的宽高
                    if (!this.$refs['pdfBox'] || !this.paperSize) return;
                    this.boxWidth = this.$refs['pdfBox'].offsetWidth;
                    this.boxHeight = this.$refs['pdfBox'].offsetHeight;
                    console.log("this.boxWidth/this.boxHeight", this.boxWidth, this.boxHeight);
                    // 获取到纸张宽高
                    let paperWidth = this.paperSize.width;
                    let paperHeight = this.paperSize.height;

                    // 获得缩放比例rr
                    let scaleH = (this.boxHeight - PageConfig.maxLengthPadding) / paperHeight;
                    let scaleW = (this.boxWidth - PageConfig.maxLengthPadding) / paperWidth;
                    let scaleMid = scaleH < scaleW ? scaleH : scaleW;
                    // this.scale = scaleMid;

                    this.scale = scaleMid < 1.45 ? 1.45 : scaleMid;// 在原有的基础上再进行放大
                    console.log("this.scale=", this.scale);
                    this.scaleWidth = paperWidth * this.scale;
                    this.scaleHeight = paperHeight * this.scale;
                    resolve();
                });
            });
        },
        // isWindows(){
        //     let agent = navigator.userAgent.toLowerCase();
        //     return agent && agent.indexOf('windows') >= 0;
        // },
        async restoreLocate(locateArray) {
            if (this.scaleWidth == -1) {
                await this.initSize();
            }
            console.log("restoreLocate-locateArray=", locateArray);
            locateArray.forEach(item => {
                item.width = Math.round(item.width * this.scale);
                item.height = Math.round(item.height * this.scale);
                item.lx = Math.round(item.lx * this.scale);
                item.ly = Math.round(this.scaleHeight - item.ly * this.scale - item.height);
            });
            // 先筛选出不需要分组的数据
            let preArray = [], groupArray = [], groupKeySet = new Set();
            for (let i = 0; i < locateArray.length; i++) {
                let item = locateArray[i];
                if (item.timeGroup) {
                    if (groupKeySet.has(item.timeGroup)) continue;
                    let dataCouple = locateArray.filter(one => one.timeGroup === item.timeGroup);
                    if (dataCouple && dataCouple.length === 2) {
                        // 只能有2个
                        let signData = {}, timeData = {};
                        if (dataCouple[0].type === 2) {
                            timeData = dataCouple[0];
                            signData = dataCouple[1];
                        } else {
                            timeData = dataCouple[1];
                            signData = dataCouple[0];
                        }
                        let showData = JSON.parse(JSON.stringify(signData));
                        showData.signData = signData;
                        showData.timeData = timeData;
                        showData.height = signData.height + timeData.height;
                        showData.width = showData.width >= timeData.width ? showData.width : timeData.width;
                        // 显示类型设置-仅前端使用
                        showData.type = signData.type === 0 ? 4 : 3;
                        groupArray.push(showData);
                    }
                    groupKeySet.add(item.timeGroup);
                } else {
                    preArray.push(item);
                }
            }
            preArray.push(...groupArray);
            console.log("restoreLocate-preArray=", preArray);
            this.locate = preArray;
            this.locateCopy = JSON.parse(JSON.stringify(preArray));
        },
        getSignStatus(sign) {
            let {haveSet} = sign;
            return haveSet ? '已指定' : '未指定';
        },
        getSignatureClass(sign) {
            let {haveSet} = sign;
            return !haveSet ? 'haveNotSetSign' : 'haveSetSign';
        },
        switchPage(add = true) {
            if (this.curPdfIndex < 2 && !add) {
                this.curPdfIndex = this.pdfPages.length
                return;
            }
            if (this.curPdfIndex >= this.pdfPages.length && add) {
                this.curPdfIndex = 1
                return;
            }

            if (add) {
                this.curPdfIndex++;
            } else {
                this.curPdfIndex--;
            }
        },
        limitPage(event) {
            let index = event.target.valueAsNumber;
            this.curPdfIndex = index;
            if (index < 2) {
                this.curPdfIndex = 1;
                return;
            }
            if (index >= this.pdfPages.length) {
                this.curPdfIndex = this.pdfPages.length;
            }
        },
        onDragStart(index, event) {
            console.log("onDragStart-index, event=", index, event);
            this.lastEvent = event
            event.dataTransfer.setData('number', index)
            // console.log(`---------------[onDragStart-this.locate]--------------`, JSON.stringify(this.locate));
        },
        onDragStop(event) {
            console.log("onDragStop-event=", event);
            console.log("onDragStop-this.lastEvent=", this.lastEvent);
            if (!event || !this.lastEvent) return;
            const diffX = event.clientX - this.lastEvent.clientX;
            const diffY = event.clientY - this.lastEvent.clientY;
            const curSignIndex = event.dataTransfer.getData('number');
            if (curSignIndex != -1) {
                // 更新签字定位
                const sign = this.locate[curSignIndex];
                console.log("onDragStop-sign=", sign);
                if (!sign) return;
                // 还需要判断当前章是否移除去了+ 手动设置坐标不可为0，后端要求
                let preX = sign.lx + diffX;
                console.log("onDragStop-preX < 0 || preX > this.scaleWidth - sign.width=", preX < 0 || preX > this.scaleWidth - sign.width);
                if (preX < 1 || preX > this.scaleWidth - sign.width) return;
                let preY = sign.ly + diffY;
                console.log("onDragStop-preY < 0 || preY > this.scaleHeight - sign.height=", preY < 0 || preY > this.scaleHeight - sign.height);
                if (preY < 1 || preY > this.scaleHeight - sign.height) return;

                // sign.ly += diffY;
                // sign.lx += diffX;
                sign.ly = Math.round(sign.ly + diffY);
                sign.lx = Math.round(sign.lx + diffX);

                sign.pageCode = this.curPdfIndex;
                console.log("onDragStop-sign=", sign);
            }
        },
        switchTimeSize() {
            let timeData = {
                addTime: Date.now(),
                haveSet: true,
                width: PageConfig.timeWidth,
                height: PageConfig.timeHeight,
                name: "YYYY年MM月DD日",
                needSet: false,
                pageCode: this.curPdfIndex,
                eventMaterialId: this.eventMaterialId,
                type: 2,
            };

            timeData.width = Math.round(timeData.width * this.scale);
            timeData.height = Math.round(timeData.height * this.scale);
            return timeData;
        },
        addTimeCurPage() {
            let timeData = this.switchTimeSize();
            timeData.ly = this.scaleHeight * 0.8;
            timeData.lx = this.scaleWidth * 0.15;
            this.locate.push(timeData);
        },
        addTimeAllPage() {
            let timeData = this.switchTimeSize();
            for (let i = 1; i <= this.pdfPages.length; i++) {
                let copy = JSON.parse(JSON.stringify(timeData));
                copy.pageCode = i;
                copy.ly = this.scaleHeight * 0.8;
                copy.lx = this.scaleWidth * 0.15;
                this.locate.push(copy);
            }
            console.log("addTimeAllPage-this.locate=", this.locate);
        },
        switchSignSize(sign, index) {
            // 偏移量增加一点
            this.factor++;
            // 浅拷贝
            let copy = JSON.parse(JSON.stringify(sign));
            // 对签字/签章进行缩放
            copy.width = Math.round(copy.width * this.scale);
            copy.height = Math.round(copy.height * this.scale);
            // 根据偏移量计算位置
            copy.ly = (this.factor === 0 ? this.factor : this.factor * 5) + (this.scaleHeight - copy.height) / 2;
            copy.lx = (this.factor === 0 ? this.factor : this.factor * 5) + (this.scaleWidth - copy.width) / 2;
            // 设置分组标记
            copy.index = index + 1;
            // 增加添加的时间戳
            copy.addTime = new Date().getTime();
            return copy;
        },
        addNewSignAllPage(sign, index) {
            let finalInfo = this.switchSignSize(sign, index);
            for (let i = 1; i <= this.pdfPages.length; i++) {
                let copy = JSON.parse(JSON.stringify(finalInfo));
                copy.pageCode = i;
                this.locate.push(copy);
            }
            console.log("addNewSignAllPage-this.locate=", this.locate);
        },
        addAllSignAllPage() {
            // for (let i = 0; i < this.signatureList.length; i++) {
            //     this.addNewSignAllPage(this.signatureList[i], i);
            // }
            this.addAllSignThisPageArrange();
            this.copyCurDataToAllPage();
        },
        addAllSignWithTimeAllPage() {
            // for (let i = 0; i < this.signatureList.length; i++) {
            //     this.addNewSignWithTimeAllPage(this.signatureList[i], i);
            // }
            this.addAllSignWithTimeThisPageArrange();
            this.copyCurDataToAllPage();
        },
        /**
         * 把当前页面的签字位置复刻到所有页面
         */
        copyCurDataToAllPage() {
            // 先从定位里面筛选出当前页面所有的签字位置
            let curPageSigns = this.locate.filter(sign => {
                return sign.pageCode === this.curPdfIndex;
            });
            console.log("copyCurDataToAllPage-curPageSigns=", curPageSigns);
            if (verify.isEmptyArray(curPageSigns)) {
                console.log("copyCurDataToAllPage-当前页面还没有任何签章！");
                this.$emit('onHint', {
                    hint: "尚未在当前页指定任何签章！",
                    data: ""
                });
                return;
            }
            // 通过配置设置是否清除掉其他页面的签字
            let newLocate = [];
            if (!this.copyAndClearOther) {
                // 如果不删除
                newLocate = this.locate.filter(sign => {
                    return sign.pageCode !== this.curPdfIndex;
                });
                console.log("不删除原来的签章-newLocate=", newLocate);
            }

            let cache = [];
            for (let i = 1; i <= this.pdfPages.length; i++) {
                cache = curPageSigns.map(curSign => {
                    let copy = JSON.parse(JSON.stringify(curSign));
                    copy.pageCode = i;
                    if (!verify.isEmpty(copy.signData)) {
                        copy.signData.pageCode = i;
                        copy.timeData.pageCode = i;
                        // 分组
                        let uuid = this.getAUuid();
                        copy.signData.timeGroup = uuid;
                        copy.timeData.timeGroup = uuid;
                    }
                    return copy;
                });
                console.log("copyCurDataToAllPage-cache=", cache);
                // for (let j = 0; j < curPageSigns.length; j++) {
                //     let curSign = curPageSigns[j];
                //     let copy = JSON.parse(JSON.stringify(curSign));
                //     copy.pageCode = j;
                //     copy.signData.pageCode = j;
                //     copy.timeData.pageCode = j;
                //     // 分组
                //     let uuid = this.getAUuid();
                //     copy.signData.timeGroup = uuid;
                //     copy.timeData.timeGroup = uuid;
                //     cache.push(copy);
                // }
                newLocate.push(...cache);
            }
            console.log("copyCurDataToAllPage-newLocate=", newLocate);
            this.locate = newLocate;
        },
        addNewSignWithTimeAllPage(sign, index) {
            let dataTemp = this.packSignWithTime(sign, index);
            for (let i = 1; i <= this.pdfPages.length; i++) {
                let copy = JSON.parse(JSON.stringify(dataTemp));
                copy.pageCode = i;
                copy.signData.pageCode = i;
                copy.timeData.pageCode = i;
                // 分组
                let uuid = this.getAUuid();
                copy.signData.timeGroup = uuid;
                copy.timeData.timeGroup = uuid;
                this.locate.push(copy);
            }
            console.log("addNewSignAllPage-this.locate=", this.locate);
        },
        addNewSign(sign, index) {
            let finalInfo = this.switchSignSize(sign, index);
            finalInfo.pageCode = this.curPdfIndex;
            this.locate.push(finalInfo);
        },
        packSignWithTime(sign, index) {
            let signInfo = this.switchSignSize(sign, index);
            let timeInfo = this.switchTimeSize();
            // 根据时间将这两个进行分组-获取唯一值
            // 这两个才是原来的数据
            signInfo.signData = JSON.parse(JSON.stringify(signInfo));
            signInfo.timeData = timeInfo;

            timeInfo.lx = signInfo.lx;
            timeInfo.ly = signInfo.ly + signInfo.height;
            // signInfo.realWidth = signInfo.width;
            // signInfo.realHeight = signInfo.height;
            signInfo.width = signInfo.width >= timeInfo.width ? signInfo.width : timeInfo.width;
            signInfo.height = signInfo.height + timeInfo.height;

            // 显示类型设置-仅前端使用
            if (sign.type === 0) {
                // 签章
                signInfo.type = 4;
            } else {
                signInfo.type = 3;
            }
            return signInfo;
        },
        addNewSignWithTime(sign, index) {
            let signWithTime = this.packSignWithTime(sign, index);
            // 设置分页
            signWithTime.pageCode = this.curPdfIndex;
            signWithTime.signData.pageCode = this.curPdfIndex;
            signWithTime.timeData.pageCode = this.curPdfIndex;
            let uuid = this.getAUuid();
            signWithTime.signData.timeGroup = uuid;
            signWithTime.timeData.timeGroup = uuid;
            this.locate.push(signWithTime);
        },
        switchSignSizeArrange(sign, index) {
            // 浅拷贝
            let copy = JSON.parse(JSON.stringify(sign));
            // 对签字/签章进行缩放
            copy.width = Math.round(copy.width * this.scale);
            copy.height = Math.round(copy.height * this.scale);
            // 根据偏移量计算位置
            // copy.ly = (this.factor === 0 ? this.factor : this.factor * 5) + (this.scaleHeight - copy.height) / 2;
            // copy.lx = (this.factor === 0 ? this.factor : this.factor * 5) + (this.scaleWidth - copy.width) / 2;
            // copy.lx = this.scaleWidth  - copy.width;
            // copy.ly = this.scaleHeight  - copy.height;

            // 设置分组标记
            copy.index = index + 1;
            // 增加添加的时间戳
            copy.addTime = new Date().getTime();
            return copy;
        },
        /**
         * @param sign 签字数据
         * @param index 分组信息
         * @param ltPos 左上角位置
         */
        addNewSignArrange(sign, index, ltPos) {
            // 将
            let finalInfo = this.switchSignSizeArrange(sign, index);
            finalInfo.pageCode = this.curPdfIndex;
            this.locate.push(finalInfo);
        },
        /**
         * @param index 第几个签字数据
         * @param lastX 上一个签字框的左侧x位置
         * @param lastBottomY 上一个签字框的底部Y位置
         */
        addNewSignArrangeThisPage(index, lastX, lastBottomY) {
            if (index > this.signatureList.length - 1) {
                // 递归结束
                return;
            }
            let curSign = this.signatureList[index];
            // 浅拷贝
            let finalInfo = JSON.parse(JSON.stringify(curSign));
            // 设置分组标记
            finalInfo.index = index + 1;
            // 增加添加的时间戳
            finalInfo.addTime = new Date().getTime();
            finalInfo.pageCode = this.curPdfIndex;

            // 对签字/签章进行缩放
            finalInfo.width = Math.round(finalInfo.width * this.scale);
            finalInfo.height = Math.round(finalInfo.height * this.scale);
            // 计算左上角位置
            let curX, curY, curBottomY;

            if (index === 0) {
                // 第一个章在右下角
                curX = this.scaleWidth - finalInfo.width;
                curY = this.scaleHeight - finalInfo.height;
                curBottomY = this.scaleHeight;
            } else {
                // 当前行摆不下这个签章
                if (lastX - finalInfo.width < 0) {
                    curX = this.scaleWidth - finalInfo.width;
                    curBottomY = lastBottomY - this.curArrangeLineSignMaxHeight - this.arrangeDivideY;
                    // 换行-取当前行最高的一个签名为基线
                    curY = curBottomY - finalInfo.height;
                    this.curArrangeLineSignMaxHeight = finalInfo.height;// 重置当前最高
                } else {
                    // 当前行可以摆下这个章
                    curX = lastX - finalInfo.width - this.arrangeDivideX;
                    curBottomY = lastBottomY;
                    curY = curBottomY - finalInfo.height;
                }
            }

            if (finalInfo.height >= this.curArrangeLineSignMaxHeight) {
                // 更新行当前最高签名高度
                this.curArrangeLineSignMaxHeight = finalInfo.height;
            }

            finalInfo.lx = curX;
            finalInfo.ly = curY;
            this.locate.push(finalInfo);
            this.addNewSignArrangeThisPage(index + 1, curX, curBottomY);
        },
        /**
         * @param index 第几个签字数据
         * @param lastX 上一个签字框的左侧x位置
         * @param lastBottomY 上一个签字框的底部Y位置
         */
        addNewSignWithTimeArrangeThisPage(index, lastX, lastBottomY) {
            if (index > this.signatureList.length - 1) {
                // 递归结束
                return;
            }
            let curSign = this.signatureList[index];
            // 浅拷贝
            let finalInfo = JSON.parse(JSON.stringify(curSign));
            // 设置分组标记
            finalInfo.index = index + 1;
            // 增加添加的时间戳
            finalInfo.addTime = new Date().getTime();
            finalInfo.pageCode = this.curPdfIndex;

            // 对签字/签章进行缩放
            let timeInfo = this.switchTimeSize();

            // 计算左上角位置
            let curX, curY, curBottomY, totalHeight = finalInfo.height + timeInfo.height;
            let realWidth = timeInfo.width >= finalInfo.width ? timeInfo.width : finalInfo.width;

            if (index === 0) {
                // 第一个章在右下角
                curX = this.scaleWidth - realWidth;
                curY = this.scaleHeight - totalHeight;
                curBottomY = this.scaleHeight;
            } else {
                // 当前行摆不下这个签章
                if (lastX - realWidth < 0) {
                    curX = this.scaleWidth - realWidth;
                    curBottomY = lastBottomY - this.curArrangeLineSignMaxHeight - this.arrangeDivideY;
                    // 换行-取当前行最高的一个签名为基线
                    curY = curBottomY - totalHeight;
                    this.curArrangeLineSignMaxHeight = totalHeight;// 重置当前最高
                } else {
                    // 当前行可以摆下这个章
                    curX = lastX - realWidth - this.arrangeDivideX;
                    curBottomY = lastBottomY;
                    curY = curBottomY - totalHeight;
                }
            }

            if (totalHeight >= this.curArrangeLineSignMaxHeight) {
                // 更新行当前最高签名高度
                this.curArrangeLineSignMaxHeight = totalHeight;
            }

            finalInfo.lx = curX;
            finalInfo.ly = curY;

            timeInfo.lx = curX;
            timeInfo.ly = curY + finalInfo.height;

            finalInfo.signData = JSON.parse(JSON.stringify(finalInfo));
            finalInfo.timeData = timeInfo;

            finalInfo.width = realWidth;
            finalInfo.height = totalHeight;

            // 显示类型设置-仅前端使用
            if (finalInfo.type === 0) {
                // 签章
                finalInfo.type = 4;
            } else {
                finalInfo.type = 3;
            }

            this.locate.push(finalInfo);
            this.addNewSignWithTimeArrangeThisPage(index + 1, curX, curBottomY);
        },
        /**
         * 有序的把签字标记从右下角排序
         */
        addAllSignThisPageArrange() {
            this.addNewSignArrangeThisPage(0, -1, -1);
        },
        /**
         * 有序的把签字标记(携带了时间)从右下角排序
         */
        addAllSignWithTimeThisPageArrange() {
            this.addNewSignWithTimeArrangeThisPage(0, -1, -1);
        },
        addAllSignThisPage() {
            for (let i = 0; i < this.signatureList.length; i++) {
                this.addNewSign(this.signatureList[i], i);
            }
        },
        addAllSignWithTimeThisPage() {
            for (let i = 0; i < this.signatureList.length; i++) {
                this.addNewSignWithTime(this.signatureList[i], i);
            }
        },
        deleteLocate(index) {
            if (this.factor !== 0) {
                this.factor--;
            }

            this.locate.splice(index, 1);
            if (this.locate.length === 0) {
                this.factor = 0
            }
        },
        packLocateData() {
            let copy = JSON.parse(JSON.stringify(this.locate));
            // 获取到前半段数据
            let preArray = copy.filter(item => item.type !== 3 && item.type !== 4);
            let waitArray = copy.filter(item => item.type === 3 || item.type === 4);
            let extendData = [];
            for (let i = 0; i < waitArray.length; i++) {
                let waiteItem = waitArray[i];
                console.log("packLocateData-waiteItem=", waiteItem);
                let signData = waiteItem.signData;
                let timeData = waiteItem.timeData;
                signData.lx = waiteItem.lx;
                timeData.lx = waiteItem.lx;
                signData.ly = waiteItem.ly;
                timeData.ly = waiteItem.ly + signData.height;
                console.log("packLocateData-signData=", signData);
                console.log("packLocateData-timeData=", timeData);
                extendData.push(signData);
                extendData.push(timeData);
            }
            preArray.push(...extendData);
            // console.log("packLocateData-preArray=", preArray);
            // // 数据整合完毕-将定位转换为真实物理位置
            const scale = this.scale;
            preArray.forEach(val => {
                val.lx = Math.round(val.lx / scale);
                val.ly = Math.round((this.scaleHeight - val.ly - val.height) / scale);
                val.width = Math.round(val.width / scale);
                val.height = Math.round(val.height / scale);
            })
            console.log("packLocateData-copy=", preArray);
            return preArray;
        },
        saveLocation() {
            console.log("saveLocation-数据整合前的loacte=", this.locate);
            // 先去判断是否所有签名都签上了
            let findOne = this.signatureList.find(sign => !sign.haveSet);
            console.log("saveLocation-findOne=", findOne);
            // 去获取数据组装后的定位数据-并传递给使用者
            let packedData = this.packLocateData();
            if (findOne) {
                this.$emit('onHint', {
                    hint: `${findOne.name}的${findOne.type === 1 ? "签字" : '签章'}未指定,是否保存？`,
                    data: packedData
                });
            } else {
                this.$emit('onComplete', {
                    orientation: this.isVertical ? 'VERTICAL' : 'HORIZONTAL',
                    points: packedData
                });
                this.hideEditor();
            }
        },
        saveLocationOld() {
            console.log(`---------------[locate]--------------`, this.locate);
            // 先进行点位确认
            for (const signature of this.signatureList) {
                // 去定位列表里面找，哪一个人没有定位，然后提醒他
                let haveLocate = this.locate.some(v => {
                    return v.name === signature.name;
                });
                if (!haveLocate) {
                    let oneCopy = JSON.parse(JSON.stringify(this.locate));
                    oneCopy.forEach(val => {
                        const scale = this.scale;
                        val.lx = Math.round(val.lx / scale);
                        val.ly = Math.round((this.scaleHeight - val.ly - val.height) / scale);
                        val.width = Math.round(val.width / scale);
                        val.height = Math.round(val.height / scale);
                    })

                    this.$emit('onHint', {
                        hint: `${signature.name}的${signature.type === 1 ? "签字" : '签章'}未指定,是否保存？`,
                        data: oneCopy
                    });
                    return;
                }
            }
            let copy = JSON.parse(JSON.stringify(this.locate));
            let finalLocate = copy.map(info => {
                let {width, height, ly, lx} = info;
                const scale = this.scale;
                info.lx = Math.round(lx / scale);
                info.ly = Math.round((this.scaleHeight - ly - height) / scale);
                info.width = Math.round(width / scale);
                info.height = Math.round(height / scale);
                return info;
            });

            this.$emit('onComplete', {
                orientation: this.isVertical ? 'VERTICAL' : 'HORIZONTAL',
                points: finalLocate
            });
            console.log(`---------------[转换后的locateInfo]--------------`, finalLocate);
            this.hideEditor();
        },
        showEditor(parameter) {
            console.log("showEditor-parameter=", parameter);
            let {signatureList, pdfPages, id, pdfAttribute, timeConfig} = parameter; //pdfAttribute纸张大小
            this.paperSize = pdfAttribute;
            PageConfig.timeWidth = timeConfig.width;
            PageConfig.timeHeight = timeConfig.height;
            this.dialogVisible = true;
            this.eventMaterialId = id;
            this.signatureList = signatureList;
            this.pdfPages = pdfPages;
        },
        cancel() {
            console.log(`---------------[this.haveLocateChange(this.locate, this.locateCopy)]--------------`, this.haveLocateChange(this.locate, this.locateCopy));
            if (this.haveLocateChange(this.locate, this.locateCopy)) {
                this.$emit('cancelChange');
                return;
            }
            this.hideEditor();
        },
        hideEditor() {
            this.dialogVisible = false;
            this.factor = 0
        },
        styleRect(rect) {
            return rect ? {
                width: `${rect.width}px`,
                height: `${rect.height}px`,
                top: `${rect.ly}px`,
                left: `${rect.lx}px`,
            } : '';
        },
        haveLocateChange(locate, oldLocate) {
            if (locate.length !== oldLocate.length) return true;
            for (const signature of locate) {
                let {name, lx, ly} = signature;
                console.log(`---------------[signature]--------------`, signature);
                let oldSignature = oldLocate.find(oldValue => oldValue.name === name);
                console.log(`---------------[oldSignature]--------------`, oldSignature);
                // 如果找不到这一条，那么就是有变更
                if (verify.isEmpty(oldSignature)) return true;
                if (oldSignature.lx !== lx || oldSignature.ly !== ly) return true;
            }
            return false;
        },
        getAUuid() {
            var s = [];
            var hexDigits = "0123456789abcdef";
            for (var i = 0; i < 36; i++) {
                s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
            }
            s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
            s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
            s[8] = s[13] = s[18] = s[23] = "-";

            return s.join("");
        },
    }
}
</script>

<style lang="scss" scoped>
.pdfPicContainer {
    position: absolute;

    .pdfPic {
        height: 100%;
        z-index: 1;
        box-shadow: 1px 1px 1px 1px #dad6d6;
    }

    .noPdfHint {
        align-self: center;
        top: 46%;
        position: fixed;
        font-size: 32px;
        font-weight: bold;
        color: #333333;
        white-space: nowrap;
        margin: 0 auto;
    }

    .dragRect {
        position: absolute;
        z-index: 999;
        display: inline-block;
        border: 1px dashed #FF1A1A;
        background-color: rgba(255, 26, 26, 0.05);
    }

    .pdfPageHandler {
        /*position: absolute;*/
        /*left: calc(50% - 48px);*/
        z-index: 100;
        /*align-self: center;*/
    }
}

.parties {
    margin-top: 1%;
    width: 40%;
    height: 60vh;
    border: 1px solid #666666;
}

.signatureStyle {
    border-radius: 8px;
    border: 1px solid #EBEBEB;
    padding: 0 4px;

    &:active {
        background-color: greenyellow;
    }
}

.signatureContainer {
    width: 25%;
    padding: 0 16px;
    margin-left: 8px;
}

.signName {
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 100%;
}

.outContainer {
    height: 86vh;
}

::v-deep .el-dialog {
    margin: 0 auto 0;

    .el-dialog__body {
        padding: 0 20px;
    }

    .el-dialog__footer {
        padding: 8px 20px 10px;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.deleteIcon {
    width: 12px;
    height: 12px;
    min-height: 12px;
    min-width: 12px;
    line-height: 12px;
    text-align: center;
    color: white;
    background-color: #FF1A1A;
    font-size: 12px;
    font-weight: bold;
    top: -1.2px;
    right: -1.2px;
    z-index: 2;

    &:active {
        background-color: #FF1A1A33;
    }
}

.signatureList {
    border-top: 1px solid #E0E0E0;
    padding: 0 8px;
}

.signatureLine {
    /*height: 40px;*/
    min-height: 40px;
    border-bottom: 1px solid #E0E0E0;
}

.signatureItem {
    min-width: 60px;
    width: 80px;
    height: 40px;
    text-align: start;
}

.signatureItemMini {
    min-width: 50px;
    width: 50px;
    height: 40px;
    text-align: start;
}

.tipLayout {
    width: 48px;
    height: 20px;
    border-radius: 2px;
}

.titleContainer {
    height: 40px;
    border-bottom: 1px solid #EBEBEB;
}

.signatureStatus {
    width: 48px;
    height: 20px;
    border-radius: 2px;
    font-size: 8px;
    line-height: 20px;
    text-align: center;
}

.haveSetSign {
    color: #32D26B;
    background-color: rgba(50, 210, 107, 0.2)
}

.haveNotSetSign {
    color: #E31E26;
    background-color: rgba(227, 30, 38, 0.15);
}

.signatureIndex {
    font-size: 30px;
    font-weight: bold;
}

.pageFontSize {
    font-size: 18px;
    /*font-weight: bold;*/
    /*position: absolute;*/
    /*right: 0;*/
}

.indexInput {
    margin: 0 4px;
    width: 50px;
    height: 20px;
    text-align: center;

}

.pdfBox {
    overflow: auto;
}

.pdfBox::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.pdfBox::-webkit-scrollbar-track {
    background: rgb(239, 239, 239);
    border-radius: 2px;
}

.pdfBox::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 10px;
}

.pdfBox::-webkit-scrollbar-thumb:hover {
    background: #333;
}

.pdfBox::-webkit-scrollbar-corner {
    background: #179a16;
}

.barStyle {
    height: 48px;
    line-height: 48px;
}

.borderTop {
    border-top: 1px solid #E0E0E0;
}

.addBtn {
    width: 100px;
    height: 48px;
}

.bottomPopContainer {
    width: 196px;
    height: fit-content;
    background-color: white;
    box-shadow: 0px 27px 80px rgba(0, 0, 0, 0.07), 0px 6.0308px 17.869px rgba(0, 0, 0, 0.0417275), 0px 1.79553px 5.32008px rgba(0, 0, 0, 0.0282725);
    border-radius: 5px;
}

.popItem {
    height: 40px;
    width: 100%;
    line-height: 40px;
    padding: 0 12px;
    color: #4E5969;
    text-align: start;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -khtml-user-selece: none;
    /*上面都是兼容性问题，具体看浏览器版本或什么浏览器*/
    user-select: none;

    &:hover {
        background-color: #F2931D;
        color: white;
    }
}

.width100px {
    width: 100px;
    min-width: 100px;
}
</style>
